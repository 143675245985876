export const GAME_BOTS = [
  {
    id: 3,
    type: 'bot',
    date: 'Jul 24, 2023',
    title: 'Manic Doge Bot',
    description:
      'Set of games and tools for Telegram. Play with your friends, rate your skills and climb up the leaderboard.',
    link: 'https://t.me/manicdogebot',
    tags: ['game', 'leaderboard'],
    img: 'gameBotImgs/manicdogebot.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/ManicDogeBot',
  },
  {
    id: 4,
    type: 'bot',
    date: 'Jun 24, 2023',
    title: 'Grumpy Cat Coin',
    description:
      'Grumpy Cat Coin is a Telegram bot to play with your friends, rate your skills and climb up the leaderboard.',
    link: 'https://t.me/GrumpyCatCoinBot',
    tags: ['game', 'leaderboard'],
    img: 'gameBotImgs/GrumpyCatCoinBot.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/GrumpyCatCoinBot',
  },
  {
    id: 18,
    type: 'bot',
    date: 'Dec 1, 2023',
    title: 'BlobPacmanBot',
    description: 'BlobPacmanBot is a telegram game bot',
    link: 'https://t.me/BlobPacmanBot',
    tags: ['game', 'pacman'],
    img: 'gameBotImgs/BlobPacmanBot.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/BlobPacmanBot',
  },
  {
    id: 19,
    type: 'bot',
    date: 'Dec 1, 2023',
    title: 'FruitNinjaBot',
    description: 'FruitNinjaBot is a telegram game bot',
    link: 'https://t.me/WELTGRABBot',
    tags: ['game', 'fruit ninja'],
    img: 'gameBotImgs/FruitNinjaBot.jpg',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/FruitNinjaBot',
  },
  {
    id: 20,
    type: 'bot',
    date: 'Dec 1, 2023',
    title: 'DuckRaceBot',
    description: 'DuckRaceBot is a racing multiplayer telegram game bot',
    link: 'https://t.me/ArcanaGameBot',
    tags: ['game', 'racing'],
    img: 'gameBotImgs/DuckRaceBot.jpg',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/DuckRaceBot',
  },
  {
    id: 23,
    type: 'bot',
    date: 'Dec 30, 2023',
    title: 'CheesyGameBot',
    description: 'CheesyGameBot is a telegram game bot',
    link: 'https://t.me/CheesyGameBot',
    tags: ['game', 'cheesy'],
    img: 'gameBotImgs/CheesyGameBot.jpg',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/CheesyGameBot',
  },
  {
    id: 24,
    type: 'bot',
    date: 'jan 10, 2024',
    title: 'PlxyerGameBot',
    description: 'PlxyerGameBot is a telegram game bot',
    link: 'https://t.me/PLXYER_Gamebot',
    tags: ['game', 'plxyer'],
    img: 'gameBotImgs/PlxyerGameBot.jpg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/PlxyerGameBot',
  },
  {
    id: 42,
    type: 'bot',
    date: 'feb 2, 2024',
    title: 'BabyNotCoin',
    description: 'just like notcoin but better',
    link: 'https://t.me/NotBabyCoinFAMBot',
    tags: ['game', 'trending'],
    img: 'gameBotImgs/notcoin.jpg',
    status: 'online',
    rating: 6,
    github: '',
  },
  {
    id: 43,
    type: 'bot',
    date: 'Feb 20, 2024',
    title: 'RedactedGameBot',
    description: 'Redacted is a maze game',
    link: 'https://t.me/Redacted404Bot',
    tags: ['game', 'maze'],
    img: 'gameBotImgs/Redacted404Bot.jpeg',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/Retadted',
  },
  {
    id: 47,
    type: 'bot',
    date: 'Feb 08, 2024',
    title: 'Asteroid shooter',
    description: 'Asteroid shooter is a telegram game bot',
    link: 'https://t.me/AsteroidShooterBot',
    tags: ['game', 'asteroid'],
    img: 'gameBotImgs/AsteroidShooter.png',
    status: 'online',
    rating: 5,
    github: '',
  },
  {
    id: 47,
    type: 'bot',
    date: 'Mar 06, 2024',
    title: 'Mammoth Meteors',
    description: 'Mammoth Meteors is a telegram game where you have to dodge meteors',
    link: 'https://t.me/PlxyerGameBot',
    tags: ['game', 'asteroid', 'dodge'],
    img: 'gameBotImgs/MammothMeteors.jpg',
    status: 'online',
    rating: 4,
    github: '',
  },
  {
    id: 48,
    type: 'bot',
    date: 'Apr 08, 2024',
    title: 'MoneyPrinterGoBRR',
    description: 'MoneyPrinterGoBRR is a telegram game for a meme coin',
    link: 'https://t.me/MoneyPrinterGoBRR_bot',
    tags: ['game', 'money', 'dodge'],
    img: 'gameBotImgs/MoneyPrinterGoBRR_bot.jpg',
    status: 'online',
    rating: 4,
    github: '',
  },
  {
    id: 35,
    type: 'game',
    date: 'Dec 30, 2023',
    title: 'HitTheBonk',
    description: 'HitTheBonk is like the arcade game whack-a-mole',
    link: 'https://t.me/HitTheBonkBot',
    tags: ['game', 'whack-a-mole'],
    img: 'gameBotImgs/HitTheBonk.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/HitTheBonk',
  },
  {
    id: 50,
    type: 'game',
    date: 'Apr 30, 2024',
    title: 'Slinky Game',
    description: '🕹 First game release from your frens at weenie 🐶🌭',
    link: 'https://t.me/slinkygamebot',
    tags: ['game', 'slinky'],
    img: 'gameBotImgs/slinkygamebot.jpg',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/WeenieGame',
  },
  {
    id: 49,
    type: 'game',
    date: 'May 30, 2024',
    title: 'FlappyUtya',
    description: 'FlappyUtya is a telegram game bot where you have to dodge obstacles',
    link: 'https://t.me/FlappyUtyaBot',
    tags: ['game', 'flappy', 'dodge'],
    img: 'gameBotImgs/FlappyUtyaBot.jpg',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/FlappyDuckUtya',
  },
  {
    id: 51,
    type: 'game',
    date: 'May 30, 2024',
    title: 'WhiskeyNinja',
    description: 'WhiskeyNinja is a telegram game bot where you have to slice fruits',
    link: 'https://t.me/WhiskeyNinjaBot',
    tags: ['game', 'fruit', 'ninja'],
    img: 'gameBotImgs/WhiskeyNinja.png',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/WhiskeyNinja',
  },
  {
    id: 52,
    type: 'game',
    date: 'Jun 30, 2024',
    title: 'MoneyPrinterGoBRRR',
    description: 'MoneyPrinterGoBRRR a telegram game for a meme coin',
    link: 'https://t.me/MoneyPrinterGoBRR_bot',
    tags: ['game', 'money', 'dodge'],
    img: 'gameBotImgs/MoneyPrinterGoBRR_bot.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/ShitCoinBot',
  },
  {
    id: 53,
    type: 'game',
    date: 'Jun 30, 2024',
    title: 'MoneyPrinterGoBRRR',
    description: 'MoneyPrinterGoBRRR a telegram game for a meme coin',
    link: 'https://t.me/MoneyPrinterGoBRR_bot',
    tags: ['game', 'money', 'dodge'],
    img: 'gameBotImgs/MoneyPrinterGoBRR_bot.png',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/ShitCoinBot',
  },
  {
    id: 54,
    type: 'game',
    date: 'Jun 10, 2024',
    title: 'AlchemistsGame',
    description: 'AlchemistsGame: Cut the tree and dodge the branches',
    link: 'https://t.me/AlchemistsGame_bot',
    tags: ['game', 'dodge', 'cut'],
    img: 'gameBotImgs/AlchemistsGame_bot.png',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/AlchemistsGame',
  },
].sort((a, b) => b.rating - a.rating);
// .filter((bot) => bot.status === 'online');
