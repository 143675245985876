import React from 'react';
import { styled } from 'styled-components';
import CardBot from '../../components/CardBot';
import { BOTS } from '../../api/BotsData';
import { WEBSITES } from '../../api/WebsitesData';
import { GAMES } from '../../api/GamesData';
import ReactGA from 'react-ga4';
import { GAME_BOTS } from '../../api/GameBotsData';

const Container = styled.section`
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  background-position: top;
  max-width: 1300px;
  margin: 0 auto;

  //smaller screens
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-right: auto;
  margin-bottom: 25px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  font-weight: 400;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5;
  margin-bottom: 100px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.1rem;
    width: 100%;
  }
`;

const ContainerInner = styled.div`
  text-align: left;

  //smaller screens
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const CardBots = styled.div`
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
  gap: 20px;
  text-align: center;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    gap: 20px;
    width: 100%;
  }
`;

const ClickForMore = styled.a`
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  font-weight: 400;
  margin: 0 auto;
  line-height: 1.5;
  display: block;
  margin-top: 100px;
  padding: 10px 20px;
  border-radius: 15px;
  max-width: 400px;
  text-decoration: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #ffffff10;
  color: #fff;
  &:hover {
    background: linear-gradient(90deg, #448ccc, #62c3fd);
  }

  //smaller screens
  @media (max-width: 768px) {
    font-size: 0.8rem;
    width: 100%;
  }
`;

const CategoryButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  margin-bottom: 50px;

  //smaller screens
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const CategoryButton = styled.button<{ active: any }>`
  background: #ffffff10;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  &:hover {
    background: linear-gradient(90deg, #448ccc, #62c3fd);
  }

  ${(props) =>
    props.active &&
    `
    background: linear-gradient(90deg, #448ccc, #62c3fd);
    color: #fff;
  `}

  //smaller screens
  @media (max-width: 768px) {
    font-size: 0.8rem;
    width: 100%;
  }
`;

export default function Work() {
  const [category, setCategory] = React.useState('all');
  const [list, setList] = React.useState(
    GAMES.slice(0, 3).concat(BOTS.concat(WEBSITES.concat(GAME_BOTS)))
  );

  React.useEffect(() => {
    if (category === 'all') {
      setList(GAMES.slice(0, 3).concat(BOTS.concat(WEBSITES)));
    } else if (category === 'games') {
      setList(GAMES);
    } else if (category === 'bots') {
      setList(BOTS);
    } else if (category === 'website') {
      setList(WEBSITES);
    } else if (category === 'telegram_games') {
      setList(GAME_BOTS);
    }
  }, [category]);

  return (
    <Container id="work">
      <ContainerInner>
        <Title>Projects That We Have Made</Title>
        <Description>
          We have a wide range of projects, from bots to websites, and even games! We are always
        </Description>
        <CategoryButtonContainer>
          <CategoryButton active={+(category === 'all')} onClick={() => setCategory('all')}>
            All
          </CategoryButton>
          <CategoryButton active={+(category === 'games')} onClick={() => setCategory('games')}>
            Games
          </CategoryButton>

          <CategoryButton active={+(category === 'bots')} onClick={() => setCategory('bots')}>
            Bots
          </CategoryButton>

          <CategoryButton
            active={+(category === 'telegram_games')}
            onClick={() => setCategory('telegram_games')}
          >
            Telegram Games
          </CategoryButton>
          <CategoryButton active={+(category === 'website')} onClick={() => setCategory('website')}>
            Wesbite
          </CategoryButton>
        </CategoryButtonContainer>

        <CardBots>
          {list.slice(0, window.innerWidth < 768 ? 3 : 8).map((bot) => (
            <CardBot key={bot.id + bot.title} {...bot} />
          ))}
        </CardBots>
      </ContainerInner>
      <ClickForMore
        href="/work"
        onClick={() => {
          ReactGA.event({
            category: 'Click',
            action: 'Click for more projects',
          });
        }}
      >
        Click here to see more pojects
      </ClickForMore>
    </Container>
  );
}
