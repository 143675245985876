import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Define styled-components for your CSS
const Container = styled.div`
  min-height: 100%;
`;
const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #fff;
  max-width: 400px;
  font-weight: 700;
  line-height: 1.2;
`;

const AccordionWrapper = styled.div`
  width: 100%;
`;

const AccordionTitle = styled.div<any>`
  padding: 15px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  color: white;
  position: relative;
  font-size: 15px;
  // background: #1f7aff;
  margin-bottom: -1px;
  text-align: left;
  font-weight: 700;
  ${(props: any) =>
    props.isex &&
    css`
      background: linear-gradient(90deg, #448ccc, #62c3fd);
    `}
`;

const AccordionContent = styled.div<any>`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s;
  margin: 0;
  padding: 0 30px;
  border-top: 0;
  background: #e8f4fc;

  p {
    padding: 30px 0;
    margin: 0;
    background: transparent;
    opacity: 0;
    transition: 0.5s;
  }

  ${(props) =>
    props.isex &&
    css`
      max-height: 500px;
      overflow: hidden;
      p {
        opacity: 1;
      }
    `}
`;

const AccordionContainer = styled.div<any>`
  margin: 0;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`;

interface AccordionProps {
  title: string;
  isex: boolean;
  answer: string;
  onClick: () => void;
}

const accordionListBot = [
  {
    title: 'Can Hidanz bots work on multiple platforms simultaneously?',
    answer:
      'Yes, Hidanz bots are designed for multi-platform integration, ensuring your community stays connected across different platforms.',
  },
  {
    title: 'What can Hidanz bots do for my community?',
    answer:
      ' Hidanz bots can automate tasks, curate content, spark conversations, and provide valuable insights, enhancing engagement and interaction within your community.',
  },
  {
    title: ' Do I need technical expertise to use Hidanz bots?',
    answer:
      "No, Hidanz offers user-friendly solutions that don't require extensive technical knowledge. Our team will guide you through the setup and customization process.",
  },
  {
    title: 'Can I see examples of communities that have benefited from Hidanz bots?',
    answer:
      'Yes, you can explore our case studies and testimonials to see how Hidanz has positively impacted various communities around the world.',
  },
  {
    title: 'How can I get started with Hidanz?',
    answer:
      "To get started, simply reach out to us through our contact page or email [your contact email], and we'll be happy to discuss your specific needs and provide a tailored solution.",
  },
  {
    title: ' What kind of support does Hidanz offer after bot deployment?',
    answer:
      'Hidanz provides ongoing support and maintenance to ensure your bots continue to perform optimally and adapt to your evolving community needs.',
  },
  {
    title: 'Is there a pricing structure for Hidanz services?',
    answer:
      'Yes, we offer competitive pricing packages tailored to the complexity and scope of your project. Contact us for a personalized quote.',
  },
];

const accordionList = [
  {
    title: 'What blockchain solutions do we offer?',
    answer:
      'We provide a range of blockchain services, including dApp design and smart contract integration.',
  },
  {
    title: 'How do we approach game development?',
    answer:
      'We use the latest technologies to create engaging games for various platforms like mobile, PC, and console.',
  },
  {
    title: 'What website development services do we provide?',
    answer: 'We design and deploy responsive websites tailored to your needs.',
  },
  {
    title: 'What about our bot development capabilities?',
    answer: 'We develop AI-powered bots for various tasks and platforms, focusing on adaptability.',
  },
  {
    title: 'Do we offer mobile app development and other programming services?',
    answer:
      'Yes, we offer mobile app development, system integration, and more to cater to diverse requirements.',
  },
  {
    title: 'How to get started with our services?',
    answer: "Contact us via our telegram or fiverr account to discuss your project's requirements.",
  },
  {
    title: 'Is there a pricing structure for our services?',
    answer: 'Our pricing varies based on the project. For specifics, please reach out to us.',
  },
];

const Accordion: React.FC<AccordionProps> = ({ title, isex, answer, onClick }) => {
  return (
    <AccordionContainer>
      <AccordionTitle isex={+isex} onClick={onClick}>
        {title}
      </AccordionTitle>
      <AccordionContent isex={+isex} onClick={onClick}>
        <p>{answer}</p>
      </AccordionContent>
    </AccordionContainer>
  );
};

const Accordation: React.FC = (props: any) => {
  const { isbot } = props;
  const [index, setIndex] = useState(0);

  const toggle = (i: number) => () => {
    setIndex((index) => (i === index ? -1 : i));
  };

  return (
    <Container>
      <Title>Frequently Asked Question</Title>

      <AccordionWrapper>
        {(isbot ? accordionListBot : accordionList).map((item, i) => (
          <Accordion
            key={i}
            title={item.title}
            answer={item.answer}
            onClick={toggle(i)}
            isex={index === i}
          />
        ))}
      </AccordionWrapper>
    </Container>
  );
};

export default Accordation;
