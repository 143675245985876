export const REVIEWS = [
  {
    date: '1 week ago',
    country: 'Slovenia',
    author: 'gbneurobeans',
    rating: 5,
    content:
      "Exceptional work! Despite the project's complexity, the process was seamless, marked by effortless communication. Highly recommend.",
  },
  {
    date: '1 week ago',
    country: 'Italy',
    author: 'jasondenverowen',
    rating: 5,
    content: 'Amazing. A very good Job ...and in a Half day. I m going to come back early here',
  },
  {
    date: '2 weeks ago',
    country: 'United States',
    author: 'cdradon89',
    rating: 5,
    content:
      'An amazing delivery. The product works perfectly. Thank you so much for your quick response and quick delivery.',
  },
  {
    date: '1 month ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content: 'Great work and faster than expected',
  },
  {
    date: '1 month ago',
    country: 'United States',
    author: 'cryptopaycheck',
    rating: 5,
    content: 'Great work as always! Will order again soon!',
  },
  {
    date: '1 month ago',
    country: 'United States',
    author: 'elevateco98',
    rating: 5,
    content: 'Good work. Very responsive.',
  },
  {
    date: '1 month ago',
    country: 'France',
    author: 'acosii',
    rating: 5,
    content:
      "Thank you for your work, all your patience and your way of understanding things have been excellent even if you didn't explain it well, I won't hesitate to work with you again in the future ❤️",
  },
  {
    date: '1 month ago',
    country: 'Morocco',
    author: 'jalalchoukri',
    rating: 5,
    content:
      "We've worked with Hidanz in order to develop a bespoke memory game, I can say he is an outstanding professional, delivers on time and knows what he does. A very rare combination of hard & soft skills. I 1000% recommend to everyone, without hesitation....",
  },
  {
    date: '3 months ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content:
      'well done. delivered. faster than required and it was everything I needed/ Awesome developer.',
  },
  {
    date: '2 months ago',
    country: 'New Zealand',
    author: 'lukast5555',
    rating: 4.7,
    content: 'Games coming along great, thanks',
  },
  {
    date: '3 months ago',
    country: 'United States',
    author: 'raffitwentyfour',
    rating: 5,
    content:
      "I've used this seller for 3 games now and he has been such an important piece to my upcoming project - more business coming his way!!!",
  },
  {
    date: '3 months ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content:
      'outstanding. the task was not at all easy. it was difficult for me to explain my needs, so I can only imagine how difficult it must have been to put my requirements into life. I really like what I got. Thank you very much!',
  },
  {
    date: '4 months ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content: 'Perfect as always. I highly recommend this developer :)',
  },
  {
    date: '4 months ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content: 'Awesome! Great developer!',
  },
  {
    date: '4 months ago',
    country: 'United States',
    author: 'mousewormmedia',
    rating: 5,
    content:
      "Hidanz was excellent and extremely helpful. I'm already sending another project his way.",
  },
  {
    date: '5 months ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content: 'Awesome developer / programmer and nice to work with',
  },
  {
    date: '5 months ago',
    country: 'Italy',
    author: 'mekvox',
    rating: 5,
    content: 'He’s been very helpful. Suggested developer',
  },
  {
    date: '5 months ago',
    country: 'Italy',
    author: 'mekvox',
    rating: 5,
    content: 'Superb! It was a pleasure to collaborate with him.',
  },
  {
    date: '5 months ago',
    country: 'Spain',
    author: 'nftsbots',
    rating: 5,
    content: 'All perfect',
  },
  {
    date: '5 months ago',
    country: 'Germany',
    author: 'dikkedai',
    rating: 5,
    content: 'Great work again. Thanks',
  },
  {
    date: '5 months ago',
    country: 'United States',
    author: 'youngneezy',
    rating: 5,
    content: 'Real fast work and good communication',
  },
  {
    date: '5 months ago',
    country: 'Poland',
    author: 'prostrona',
    rating: 5,
    content:
      'Excellent communication and problem solving skills. Very patient and professional developer.',
  },
  {
    date: '6 months ago',
    country: 'New Zealand',
    author: 'lukast5555',
    rating: 5,
    content:
      "I had a very particular vision which I tried to describe a few times and I was perfectly understood. What is more my vision was brought to life by this developer in a much better way than I could have imagined. The seller was polite, very patient and professional. I'm definitely buying from him again.",
  },
  {
    date: '6 months ago',
    country: 'Taiwan',
    author: 'pr_owl',
    rating: 5,
    content: 'Amazing game!',
  },
  {
    date: '7 months ago',
    country: 'United States',
    author: 'raffitwentyfour',
    rating: 5,
    content:
      'Helped me make it for a long time, very nice person~ he is very serious in his work, and his communication is also very aggressive. He has been working hard to fulfill my request, especially when my request is too high, he still works hard to complete it. recommended~~',
  },
  {
    date: '7 months ago',
    country: 'United States',
    author: 'ibygcrypto',
    rating: 5,
    content:
      'My second time working with Hidanz - always a pleasure! Able to make edits to suit your needs and very knowledgeable.',
  },
  {
    date: '7 months ago',
    country: 'Australia',
    author: 'accumul8',
    rating: 5,
    content: 'Seller does great work.',
  },
  {
    date: '7 months ago',
    country: 'United States',
    author: 'ibygcrypto',
    rating: 5,
    content: 'This man is the best dont use anyone else !',
  },
  {
    date: '7 months ago',
    country: 'United States',
    author: 'ibygcrypto',
    rating: 5,
    content: 'Seller does great work',
  },
  {
    date: '7 months ago',
    country: 'Brazil',
    author: 'jorgefieza',
    rating: 5,
    content: 'Incredibly fast and understanding. Created my solution in node.js as was asked.',
  },
  {
    date: '8 months ago',
    country: 'United States',
    author: 'ibygcrypto',
    rating: 5,
    content: 'Seller does great work',
  },
  {
    date: '8 months ago',
    country: 'United States',
    author: 'raffitwentyfour',
    rating: 5,
    content:
      'Very well versed in game development and worked with me on several customizations. Will be using him again.. and again! Was professional throughout the entire process and easy to work with. Thank you.',
  },
  {
    date: '8 months ago',
    country: 'United States',
    author: 'ibygcrypto',
    rating: 5,
    content: 'Great work',
  },
  {
    date: '8 months ago',
    country: 'United States',
    author: 'mcdavisyf',
    rating: 5,
    content:
      'Created exactly what I requested from my directions. Was extremely easy to work with and created the bot exactly as I wanted.',
  },
  {
    date: '8 months ago',
    country: 'Germany',
    author: 'dikkedai',
    rating: 5,
    content:
      'everything worked out great. Super work, fast delivery, responded very quickly. Thank you',
  },
  {
    date: '9 months ago',
    country: 'United Kingdom',
    author: 'cashforkids',
    rating: 5,
    content:
      "It was amazing working with Hidanz on our project. I'm sometimes skeptical when using sites like this but he was amazing! Brilliant to work with and nothing was too much! HIGHLY RECOMMEND! Definitely coming back to expand on what we have built in the new year!",
  },
  {
    date: '9 months ago',
    country: 'United Kingdom',
    author: 'zamansaab786',
    rating: 5,
    content: 'Great website and fast delivery',
  },
  {
    date: '9 months ago',
    country: 'Ukraine',
    author: 'syroni112',
    rating: 5,
    content: 'The best experience ever :)',
  },
  {
    date: '9 months ago',
    country: 'United Kingdom',
    author: 'gcdbreeze',
    rating: 5,
    content:
      "This is my second booking and I couldn't recommend highly eneough. Super helpful with fast, high quality work.",
  },
  {
    date: '10 months ago',
    country: 'United Kingdom',
    author: 'gcdbreeze',
    rating: 5,
    content:
      "Hidanz is so, so good. Communication was amazing. So helpful and super talented at what he does. Save yourself the time of looking around, just book Hidanz. After spending a long time in developing a clear brief, and sending it to many sellers. Hidanz was the first to respond, understand and quote. Then when it came to the work he delivered great quality work in advance of the deadline and worked through some minor alterations. I've already got a new project lined up to work with Hidanz again.",
  },
  {
    date: '10 months ago',
    country: 'United States',
    author: 'michaelmattaous',
    rating: 5,
    content: 'Excellent work, fast, efficient, high-quality work.',
  },
  {
    date: '11 months ago',
    country: 'Egypt',
    author: 'abulama131',
    rating: 5,
    content:
      'Best game developer prefect work support even after delivery for many times strongly recommended',
  },
  {
    date: '11 months ago',
    country: 'United States',
    author: 'michaelmattaous',
    rating: 5,
    content:
      'Hidanz is AMAZING!! He really went above and beyond. No seller has been this courteous, responsive, and skilled like Hidanz. 100% I recommend him and will definitely be coming back for his services in the future.',
  },
  {
    date: '11 months ago',
    country: 'Germany',
    author: 'vazzili',
    rating: 5,
    content:
      'This is the second time I have worked with hidanz. Highly professional, skilled and fast responding. Definitively recommending this person. I will continue working with him.',
  },
  {
    date: '11 months ago',
    country: 'United Kingdom',
    author: 'twoeasy158',
    rating: 5,
    content:
      'Wanted a specific project and hidanz delivered exactly what I wanted. After messaging countless other game devs Hidanz is the only one that provided a fast, cheap and satisfying product. Recommended.',
  },
  {
    date: '1 year ago',
    country: 'GB',
    author: 'jayjay2953',
    rating: 5,
    content:
      'Fantastic experience, he really went above and beyond to create what we wanted. He also gave his viewpoint on how to make the game better',
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'fritzuchiwa',
    rating: 5,
    content:
      'Excellent Job. The kind of developer I need to keep in touch with. The delivery was fast and accurate. I will definitely order again and looking forward to our future collaboration. Thank you so much. You saved my life.',
  },
  {
    date: '1 year ago',
    country: 'GR',
    author: 'alexsporos',
    rating: 4.7,
    content:
      'Very strait forward seller, highly skilled and no-nonsense communication from him. I just described what the job was, he perfectly understood and delivered on time. He also performed all the revisions I requested very fast. Thanks Hidanz for a job well done.',
  },
  {
    date: '1 year ago',
    country: 'FR',
    author: 'sean_jeldyh',
    rating: 3.7,
    content: 'travail satisfaisant',
  },
  {
    date: '1 year ago',
    country: 'DE',
    author: 'secimen',
    rating: 5,
    content: 'everything as promised',
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'groupcouponz',
    rating: 5,
    content: 'Great job! This was phase one. Let’s move on to phase two',
  },
  {
    date: '1 year ago',
    country: 'GB',
    author: 'gcdbreeze',
    rating: 5,
    content:
      'Super dedicated to getting it just right, and helped out with some minor bug on our site to help execute our project. Great service.',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content: 'Best developer as always',
  },
  {
    date: '1 year ago',
    country: 'SG',
    author: 'lanashy83',
    rating: 5,
    content: 'Thank you for your revision work!',
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'kougatheween',
    rating: 5,
    content: 'Wonderful to work with and very easy to communicate with. Highly recommended!',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content: 'Seller doing very good on developing. Will order again more',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content: 'Thank you for order',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content: 'Exellent work as always. Thank you so much!',
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'matt_pnw',
    rating: 2.7,
    content:
      'I originally contacted hidanz to make a few small modifications to a repo to make it mobile-friendly. He replied asking if he needed to use the repo or if he could re-create the entire project himself. I let him know the features in the original...',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content:
      "Hidanz really a good talent in game development. This is my first time working on this and I'm very lucky to meet him. He's very patient, good communicator and completed every task I asked. I will come back for more and keep working with him in future. Highly recommend",
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'hmax2017',
    rating: 5,
    content:
      "Really enjoyed woking with hidanz. He made himself available and resolved any issues as soon as I mentioned them! He's a very inventive and intelligent programmer. Highly recommended :)",
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'kougatheween',
    rating: 5,
    content: 'Wonderful! Quick turnaround and well written. Thank you!',
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'rwalk1955',
    rating: 5,
    content: 'GREAT JOB',
  },
  {
    date: '1 year ago',
    country: 'US',
    author: 'mikeyluplow',
    rating: 5,
    content: 'I love it!',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content: 'Very excellent work and would order again!',
  },
  {
    date: '1 year ago',
    country: 'VN',
    author: 'kaitlam14',
    rating: 5,
    content:
      "I'm working on this game project with Hidanz for the second time. He is really excellent in terms of game programming. His script always works smoothly with perfect project file structure. I highly recommend him to you all. Thank you so much Hidanz for your great help!",
  },
];

export const BEST_REVIEWS = [
  {
    date: '1 month ago',
    rating: 5,
    author: 'jalalchoukri',
    country: '🇲🇦',
    content:
      "We've worked with Hidanz in order to develop a bespoke memory game, I can say he is an outstanding professional, delivers on time and knows what he does. A very rare combination of hard & soft skills. I 1000% recommend to everyone, without hesitation....",
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/70b6b95140f6cbbc6c085ff8959ba5ec-1567340411254/a13ae188-faac-47b6-9d38-81654b4f3ce8.jpg',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '3 months ago',
    rating: 5,
    author: 'prostrona',
    country: '🇵🇱',
    content:
      'well done. delivered. faster than required and it was everything I needed/ Awesome developer.',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/eccdf5a8510e0ba5b8a031aade669e2f-1677147306899/f5651bea-47ca-4e26-a2a1-7a7ac8efb53c.jpg',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '9 months ago',
    rating: 5,
    author: 'cashforkids',
    country: '🇬🇧',
    content: `It was amazing working with Hidanz on our project. I'm sometimes skeptical when using sites like this but he was amazing! Brilliant to work with and nothing was too much! HIGHLY RECOMMEND! Definitely coming back to expand on what we have built in the new year!`,
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/73fae1b99c87ad6e1c9daad242292be5-1669304890582/c8f654d2-2f00-4db3-a897-afa6ae869be1.jpg',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '3 months ago',
    rating: 5,
    author: 'raffitwentyfour',
    country: '🇺🇸',
    content:
      "I've used this seller for 3 games now and he has been such an important piece to my upcoming project - more business coming his way!!!",
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/159d7245fe79d10d2d581fed1dfafe71-1672770904435/1cfb3382-9dd5-49d8-9e06-fb5033fed498.jpg',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '4 months ago',
    rating: 5,
    author: 'mcdavisyf',
    country: '🇺🇸',
    content:
      'Created exactly what I requested from my directions. Was extremely easy to work with and created the bot exactly as I wanted.',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/bca9b1a8a82e554ad48c301234bce768-1660227352126/ff71c1aa-4939-4b77-9ac0-0d1c99472cc4.jpg',
    purchased: {
      title: 'Chatbot Development',
      link: 'https://www.fiverr.com/hidanz/develop-custom-telegram-bot-for-you?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '6 months ago',
    rating: 5,
    author: 'michaelmattaous',
    country: '🇺🇸',
    content:
      'Hidanz is AMAZING!! He really went above and beyond. No seller has been this courteous, responsive, and skilled like Hidanz. 100% I recommend him and will definitely be coming back for his services in the future.',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/4ba65cb565c1574c5fe38f089c808da9-1666755112213/689659f4-a4a0-42d0-ba37-e46f600bd63f.jpg',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '2 months ago',
    rating: 5,
    author: 'cryptopaycheck',
    country: '🇺🇸',
    content: 'Great work as always! Will order again soon!',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/e842d321421d0413bc77fbd9e34059bf-1673051047019/bdf94fad-ca92-4d9d-b977-37d13336f27a.jpg',
    purchased: {
      title: 'Chatbot Development',
      link: 'https://www.fiverr.com/hidanz/develop-custom-telegram-bot-for-you?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '6 months ago',
    rating: 4.7,
    author: 'lukast5555',
    country: '🇳🇿',
    content: 'Games coming along great, thanks.',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/4746ff967a6b7424a18ad8881454bef1-1675299367656/46ebed3d-9c78-4928-bd1a-9917c11c9a52.jpg',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '7 months ago',
    rating: 5,
    author: 'youngneezy',
    country: '🇺🇸',
    content: 'Real fast work and good communication',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/c9503c2f5f7b5128a08145e68a3171c4-226820361653339400.3978848/931F2C08-0F60-49A9-8869-760FEEEFEDAE',
    purchased: {
      title: 'Chatbot Development',
      link: 'https://www.fiverr.com/hidanz/develop-custom-telegram-bot-for-you?source=user_page&ref_ctx_id=47d35734066664b238dac589b3891032',
    },
  },
  {
    date: '3 days ago',
    rating: 5,
    author: 'cjvmedia',
    country: '🇺🇸',
    content:
      'Excellent communication, worked to deliver gaming engine that worked as designed. Was very prompt with revisions and was very easy to work with.',
    avatar:
      'https://fiverr-res.cloudinary.com/video/upload/t_gig_cards_web/gmuhyxr1fmkys8g8o8hc.png',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=seller_page&ref_ctx_id=c7e86dd10bb04c1194b16d4daae1bcc4',
    },
  },
  {
    date: '1 month ago',
    rating: 5,
    author: 'johnnyalice',
    country: '🇺🇸',
    content:
      'I think I was very difficult to work with but the seller overcame it and did a great job in spite of me.',
    avatar:
      'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto,t_profile_small/v1/attachments/profile/photo/64ecd4f06ca72b0975db047cd671c44f-1564202572074/41bfbfe5-2de0-44eb-8166-6a4108791728.jpeg',
    purchased: {
      title: 'Chatbot Development',
      link: 'https://www.fiverr.com/hidanz/develop-custom-telegram-bot-for-you?source=seller_page&ref_ctx_id=c7e86dd10bb04c1194b16d4daae1bcc4',
    },
  },
  {
    date: '3 months ago',
    rating: 5,
    author: 'gbneurobeans',
    country: '🇸🇮',
    content: `Once again, I've received exceptional service from this individual. His expertise and dedication are clearly evident, making him a highly recommendable choice for any game development project.`,
    avatar:
      'https://fiverr-res.cloudinary.com/video/upload/t_gig_cards_web/gmuhyxr1fmkys8g8o8hc.png',
    purchased: {
      title: 'Full Game Creation',
      link: 'https://www.fiverr.com/hidanz/make-you-a-full-html5-javascript-game?source=seller_page&ref_ctx_id=c7e86dd10bb04c1194b16d4daae1bcc4',
    },
  },
  {
    date: '4 months ago',
    rating: 5,
    author: 'jasondenverowen',
    country: '🇮🇹',
    content: `Amazing. A very good Job ...and in a Half day. I m going to come back early here`,
    avatar:
      'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto,f_auto/gigs/275557709/original/95246b6f98957e7f78bcbc9063b10ab9ed09bdfb.png',
    purchased: {
      title: 'Chatbot Development',
      link: 'https://www.fiverr.com/hidanz/develop-custom-telegram-bot-for-you?source=seller_page&ref_ctx_id=c7e86dd10bb04c1194b16d4daae1bcc4',
    },
  },
  {
    date: '4 months ago',
    rating: 5,
    author: 'cdradon89',
    country: '🇺🇸',
    content: `An amazing delivery. The product works perfectly. Thank you so much for your quick response and quick delivery.`,
    avatar:
      'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto,f_auto/gigs/275557709/original/95246b6f98957e7f78bcbc9063b10ab9ed09bdfb.png',
    purchased: {
      title: 'Chatbot Development',
      link: 'https://www.fiverr.com/hidanz/develop-custom-telegram-bot-for-you?source=seller_page&ref_ctx_id=c7e86dd10bb04c1194b16d4daae1bcc4',
    },
  },
  {
    date: '1 week ago',
    rating: 5,
    author: '$SOLAMA Team',
    country: '🇺🇸',
    content: `Exceptional work! Despite the project's complexity, the process was seamless, marked by effortless communication. Highly recommend.`,
    avatar: 'https://i.imgur.com/8ZolcF0.png',
    purchased: {
      title: 'Telegram Bot Development',
      link: 'https://t.me/SolamaTaskBot?start=whomademe',
    },
  },
];
