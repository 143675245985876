export const WEBSITES = [
  {
    id: 1,
    type: 'website',
    date: 'Oct 10, 2023',
    title: 'Hidanz Dev',
    description: 'Hidanz Dev is a Portfolio and Landing Page for Hidanz Dev.',
    link: 'https://hidanz.dev/',
    tags: ['portfolio', 'landing page'],
    img: 'WebsiteImgs/hidanz.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/hidanzbot',
  },
  {
    id: 2,
    type: 'website',
    date: 'Apr 22, 2022',
    title: 'Pacman Verse',
    description: 'Set of games and tools themed around the Pacman universe.',
    link: 'https://pacmanverse.netlify.app/',
    tags: ['game', 'landingpage', 'crypto'],
    img: 'WebsiteImgs/pacmanverse.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/pacmanverse',
  },
  {
    id: 3,
    type: 'website',
    date: 'Mar 29, 2023',
    title: 'bankies',
    description: 'Landing page for bankies meme coin.',
    link: 'https://bankies.netlify.app/',
    tags: ['meme', 'crypto', 'landingpage'],
    img: 'WebsiteImgs/bankies.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/PREDBOT',
  },
  {
    id: 4,
    type: 'website',
    date: 'Jul 17, 2023',
    title: 'Owlwatch',
    description: 'DeFi app for tracking and trading your crypto assets.',
    link: 'https://owlwatch.netlify.app',
    tags: ['crypto', 'Defi'],
    img: 'WebsiteImgs/owlwatch.webp',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/owlwatch',
  },
  {
    id: 5,
    type: 'website',
    date: 'Jun 6, 2023',
    title: 'Dark Kermit',
    description: 'A landing website to promote Dark Kermit meme coin.',
    link: 'https://dark-kermit.netlify.app/',
    tags: ['crypto', 'meme'],
    img: 'WebsiteImgs/dark-kermit.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/GrumpyCatCoinBot',
  },
  {
    id: 6,
    type: 'website',
    date: 'May 25, 2023',
    title: 'Dexytools',
    description: 'Defi app for tracking and trading your crypto assets.',
    link: 'https://dexytools.netlify.app',
    tags: ['DeFi', 'Crypto'],
    img: 'WebsiteImgs/dexytools.webp',
    status: 'offline',
    rating: 5,
    github: 'https://github.com/Ghassanee/dexytools',
  },
  {
    id: 7,
    type: 'website',
    date: 'Dec 3, 2021',
    title: 'Circuit Lab',
    description: 'Interactive circuit simulator for STEM education.',
    link: 'https://circuit-lab.netlify.app/',
    tags: ['education', 'STEM'],
    img: 'WebsiteImgs/circuit-lab.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/circuit-lab',
  },
  {
    id: 8,
    type: 'website',
    date: 'Nov 8, 2021',
    title: 'Refrigeration Lab',
    description: 'Interactive Refrigeration Lab for STEM education.',
    link: 'https://refrigeration-lab.netlify.app/',
    tags: ['education', 'STEM'],
    img: 'WebsiteImgs/refrigeration-lab.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/refrigeration-lab',
  },
  {
    id: 9,
    type: 'website',
    date: 'Apr 29, 2022',
    title: 'Meta Force',
    description: 'Landing page for Meta Force, a crypto project.',
    link: 'https://meta-force.netlify.app/',
    tags: ['crypto', 'landingpage'],
    img: 'WebsiteImgs/meta-force.webp',
    status: 'online',
    rating: 3,
    github: 'https://github.com/Ghassanee/meta-force',
  },
  {
    id: 10,
    type: 'website',
    date: 'Aug 16, 2022',
    title: 'jrmarketing',
    description: 'Portfolio and Landing Page for JR Marketing.',
    link: 'https://jrmarketing.netlify.app/',
    tags: ['portfolio', 'landingpage'],
    img: 'WebsiteImgs/jrmarketing.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/jrmarketing',
  },
  {
    id: 11,
    type: 'website',
    date: 'Dec 5, 2022',
    title: 'Red Nose Inu',
    description: 'Landing page for Red Nose Inu meme coin.',
    link: 'https://rednoseinu.netlify.app/',
    tags: ['meme', 'crypto', 'landingpage'],
    img: 'WebsiteImgs/rednoseinu.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/rednoseinu',
  },
  {
    id: 12,
    type: 'website',
    date: 'Dec 24, 2022',
    title: 'Proof Of X',
    description: 'Defi app for tracking and trading your crypto assets.',
    link: 'https://proofofx.netlify.app',
    tags: ['DeFi', 'Crypto'],
    img: 'WebsiteImgs/proofofx.webp',
    status: 'offline',
    rating: 4,
    github: 'https://github.com/Ghassanee/proofofx',
  },
  {
    id: 13,
    type: 'website',
    date: 'Jun 8, 2022',
    title: 'Chain Charts',
    description: 'Defi app for tracking and trading your crypto assets.',
    link: 'https://chain-charts.netlify.app/',
    tags: ['DeFi', 'Crypto'],
    img: 'WebsiteImgs/chain-charts.webp',
    status: 'offline',
    rating: 3,
    github: 'https://github.com/Ghassanee/chain-charts',
  },
  {
    id: 14,
    type: 'website',
    date: 'Dec 2, 2022',
    title: 'Subjectify',
    description: 'Fun app to find out what your favorite subject is.',
    link: 'https://subjectify.netlify.app/',
    tags: ['fun', 'education'],
    img: 'WebsiteImgs/subjectify.webp',
    status: 'online',
    rating: 5,
    github: 'https://github.com/Ghassanee/subjectify',
  },
  {
    id: 15,
    type: 'website',
    date: 'Dec 2, 2022',
    title: 'Particle AI',
    description: 'Landing page for Particle AI, a crypto project.',
    link: 'https://particle-ai.netlify.app/',
    tags: ['crypto', 'landingpage'],
    img: 'WebsiteImgs/particle-ai.webp',
    status: 'online',
    rating: 4,
    github: 'https://github.com/Ghassanee/particle-ai',
  },
  {
    id: 16,
    type: 'website',
    date: 'Dec 15, 2023',
    title: 'BannerGenerator',
    description: 'BannerGenerator is a website to generate banners.',
    link: 'https://banner-generator-a.netlify.app/',
    tags: ['website', 'banner', 'generator'],
    img: 'WebsiteImgs/BannerGenerator.webp',
    status: 'online',
    rating: 6,
    github: 'https://github.com/Ghassanee/BannerGenerator',
  },
  {
    id: 16,
    type: 'website',
    date: 'Dec 24, 2023',
    title: 'Hidanz Dex',
    description: 'Defi app for tracking and trading  crypto tokens.',
    link: 'https://hidanz-dex.netlify.app/',
    tags: ['DeFi', 'Crypto'],
    img: 'WebsiteImgs/dexytools.png',
    status: 'online',
    rating: 7,
    github: 'https://github.com/Ghassanee/dexytools',
  },
]
  .sort((a, b) => b.rating - a.rating)
  .filter((bot) => bot.status === 'online');
