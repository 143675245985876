import React, { useEffect } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { BEST_REVIEWS } from '../../api/Reviews';
import ReactGA from 'react-ga4';

const Container = styled.div`
  max-width: 1300px;
  margin: auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 300px;

  //smaller screens
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 0;
  }
`;

const ReviewWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 70px 0 10px 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const ReviewRating = styled.div`
  svg {
    width: 14px;
    color: gold;
  }
`;

const SwiperPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
`;

const SwiperPagination = styled.span<any>`
  width: 10px;
  height: 10px;
  background-color: #fff;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const ReviewHeader = styled.div`
  margin-bottom: 1em;
  gap: 1em;

  .review-date {
    font-size: 12px;
    font-weight: 300;
    color: #eee;
  }

  .author-name {
    font-size: 22px;
    font-weight: 500;
  }

  .author-country {
    font-size: 22px;
    font-weight: 300;
    color: #eee;
  }
`;

const ReviewContent = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
`;

const NextSlideButton = styled.img`
  position: absolute;
  right: 20px;
  transform: translateY(-100%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const PrevSlideButton = styled.img`
  position: absolute;
  left: 20px;
  transform: translateY(-100%);
  width: 25px;
  height: 25px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: all 0.3s ease-in-out;
`;

const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-right: auto;
  margin-bottom: 25px;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;
const Description = styled.p`
  font-size: 1.2rem;
  color: #f0e4e4;
  text-align: center;
  font-weight: 700;
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.5;

  .number {
    // underline yellow
    font-weight: 700;
    font-size: 1.4rem;
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fff;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    margin: 0 0.3rem;
  }

  .number2 {
    // underline blue
    font-weight: 700;
    font-size: 1.4rem;
    margin: 0 0.3rem;
    text-decoration: underline;
    // underline green
    text-decoration-color: #fff;
    color: #fff;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
`;
const ReviewerAvatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0.5rem auto;
`;

const ReviewPurchase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  gap: 1em;
  span {
    font-size: 12px;
    font-weight: 300;
    color: #eee;
  }

  div {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ReviewSlider = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [swiper, setSwiper] = React.useState<any>(null);

  useEffect(() => {
    const swiperObject = new Swiper('.swiper-container', {
      spaceBetween: 0,
      centeredSlides: true,
      loop: true,
      effect: 'slide',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    swiperObject.on('slideChange', () => {
      setCurrentSlide(swiperObject.realIndex);
    });

    const interval = setInterval(() => {
      swiperObject.slideNext();
    }, 5000);

    setSwiper(swiperObject);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (swiper) {
      if (currentSlide === swiper.realIndex) return;
      swiper.slideTo(currentSlide);
    }
    // eslint-disable-next-line
  }, [currentSlide]);

  const click = (link: string, title: string) => {
    ReactGA.event({
      category: 'User',
      action: 'Click',
      label: `Click the purchased to ${title}`,
    });

    window.open(link, '_blank');
  };

  return (
    <Container>
      <Title> Testimonials </Title>
      <Description>
        Over
        <span className="number">+90</span> reviews,
        <span className="number">+100</span> satisfied clients, and
        <span className="number">+150</span>
        successful projects. With <span className="number2">+3 years of experience</span>
        in the field, we are the best choice for your next project.
      </Description>

      <div className="swiper-container">
        <div className="swiper-wrapper">
          {BEST_REVIEWS.map((review, index) => (
            <div key={index} className="swiper-slide">
              <ReviewWrapper>
                <ReviewHeader>
                  <div className="review-date">{review.date}</div>
                  <ReviewerAvatar src={review.avatar} alt={review.author} />
                  <div className="author-name">{review.author}</div>
                  <div className="author-country">{review.country}</div>
                  <ReviewRating>
                    {Array.from({ length: review.rating }).map((_, i) => (
                      <svg
                        key={i}
                        className="svg-inline--fa fa-star fa-w-18 final-star"
                        aria-hidden="true"
                        data-prefix="fa"
                        data-icon="star"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                        ></path>
                      </svg>
                    ))}
                  </ReviewRating>
                </ReviewHeader>
                <ReviewContent>{review.content}</ReviewContent>
                <ReviewPurchase>
                  <span>Purchased</span>
                  <div onClick={() => click(review.purchased.link, review.purchased.title)}>
                    {review.purchased.title}
                  </div>
                </ReviewPurchase>
              </ReviewWrapper>
            </div>
          ))}
        </div>
        <SwiperPaginationContainer>
          {BEST_REVIEWS.map((_, index) => (
            <SwiperPagination
              key={index}
              active={+(currentSlide === index)}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </SwiperPaginationContainer>
        <NextSlideButton
          onClick={() => swiper.slideNext()}
          src="https://img.icons8.com/ios/20/ffffff/chevron-right.png"
          alt="next slide"
        />
        <PrevSlideButton
          onClick={() => swiper.slidePrev()}
          src="https://img.icons8.com/ios/20/ffffff/chevron-left.png"
          alt="previous slide"
        />
      </div>
    </Container>
  );
};

export default ReviewSlider;
