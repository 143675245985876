import styled from 'styled-components';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import LogoGlitch from './LogoGlitch';

const NavbarContainer = styled.nav`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  position: fixed;
  top: 0;
  color: #fff;
  left: 0;
  right: 0;
  z-index: 99;
  width: 1300px;
  margin: 0 auto;
  gap: 20px;
  //smaller screens

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    filter: blur(10px);
    width: 100%;
    height: 10em;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const ListContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  background: transparent;

  //smaller screens
  @media (max-width: 768px) {
    display: none;
  }
`;

const Hamburger = styled.div<{ open: boolean }>`
  display: none;
  background: transparent;
  z-index: 999;
  span {
    width: 35px;
    height: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
    transform-origin: center;
    &:nth-child(1) {
      transform: ${(props: any) => (props.open ? 'rotate(-45deg) translate(-4px, 4px)' : 'none')};
    }
    &:nth-child(2) {
      display: ${(props: any) => (props.open ? 'none' : 'block')};
    }
    &:nth-child(3) {
      transform: ${(props: any) => (props.open ? 'rotate(45deg) translate(-6px, -6px)' : 'none')};
    }
  }

  //smaller screens
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    cursor: pointer;
    margin-left: auto;
  }
`;

const Drawer = styled.ul<{ open: boolean }>`
  position: fixed;
  background: transparent;
  top: 0;
  right: 0;
  width: 200px;
  height: 100vh;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  gap: 60px;
  transform: ${(props: any) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 99;
`;

const ListItem = styled.li`
  font-size: 1.1rem;
  background: transparent;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  &:hover {
    color: #29b6f6;
  }
  list-style: none;

  //smaller screens
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// const Logo = styled.img`
//   width: 100px; /* Adjust the size as needed */
//   height: auto;
//   cursor: pointer;
//   position: absolute;
//   top: 20px;
//   background: transparent;
//   left: 20px;
// `;

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const click = (link: string) => {
    ReactGA.event({
      category: 'Click',
      action: `Clicked on ${link}`,
    });
    window.open(link, '_self');
  };
  return (
    <NavbarContainer>
      {/* <Logo src={logo} alt="logo" onClick={() => click('/')} /> */}
      <LogoGlitch click={() => click('/')} />
      <ListContainer>
        <ListItem onClick={() => click('/')}>Home</ListItem>
        <ListItem onClick={() => click('/games/work')}>Games</ListItem>
        <ListItem onClick={() => click('/bots/work')}>Bots</ListItem>
        <ListItem onClick={() => click('/bots/gameBots')}>Telegram Games</ListItem>
        <ListItem onClick={() => click('/websites/work')}>Websites</ListItem>
      </ListContainer>

      <Hamburger onClick={() => setOpen(!open)} open={open}>
        <span></span>
        <span></span>
        <span></span>
      </Hamburger>

      <Drawer open={open}>
        <ListItem onClick={() => click('/')}>Home</ListItem>
        <ListItem onClick={() => click('/games/work')}>Games</ListItem>
        <ListItem onClick={() => click('/bots/work')}>Bots</ListItem>
        <ListItem onClick={() => click('/bots/gameBots')}>Telegram Games</ListItem>
        <ListItem onClick={() => click('/websites/work')}>Websites</ListItem>
        <ListItem onClick={() => click('#contact')}>Contact</ListItem>
      </Drawer>
    </NavbarContainer>
  );
};

export default Navbar;
